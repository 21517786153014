html {
  height: 100%;
}
body {
  min-height: 100%;
  font-family: 'Playfair Display', serif;
  margin: 0px;
}
#root {
  min-height: 100vh;
  background: #006633;
}

.MainDiv {
  text-align: center;
  height: auto;
}

#gdprText {
  text-align: left;
  margin: 0.75em;
  line-height: normal;
  color: #ffe99e;
  font-size: 1.2em;
}

#validTokenDiv h3 {
  color: #ffe99e;
}
#invalidTokenDiv h3 {
  color: red;
}

.redirectionText {
  color: white;
  padding-top: 2em;
}

img.redirectionLoader {
  position: relative;
  top: 0.75em;
}

a {
  color: rgb(255, 211, 0);
}

#smsTokenDiv input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
